
import { CourseSeries } from 'momai'
import { computed, defineComponent, ref, watch } from 'vue'
import { courseSeriesList } from '@/states/couseSeries'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true
    }
  },
  setup: (props, { emit }) => {
    const showPicker = ref(false)
    const tagIds = computed(() => props.value.split(',').map(str => Number(str)))
    const checkedCourses = ref<CourseSeries[]>([])
    const setCheckedCourses = () => {
      checkedCourses.value = courseSeriesList.value.filter(t => tagIds.value.some(id => id === t.id))
    }
    setCheckedCourses()
    watch(checkedCourses, newCheckedCourses => {
      emit('update:value', newCheckedCourses.map(t => t.id).join(','))
    })
    watch(() => props.value, setCheckedCourses)
    return {
      showPicker,
      checkedCourses,
      courseSeriesList
    }
  }
})
